import GoogleMapReact from 'google-map-react';
import React, {useState,useRef} from 'react';
import useSuperCluster from 'use-supercluster';
//import projectmarker from './project-marker.js';
import points from './feature_array.js';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery,Link} from 'gatsby';
import { useMediaQuery } from 'react-responsive';

import {markerStyle,
        clusterStyle,
        countStyle,
        clusterToolTipStyle,
        toolTipStyle,
        toolTipText,
        toolTipImage,
        mapStyle,
        mapContainer
    } from '../css/googleMap.module.css';
import query from 'doom/lib/query.js';

const Marker = ({ children }) => children;
const ToolTip = (props)=> {  
    let image;
    props.data.toolTip.edges.forEach(edge=>{    
        if(edge.node.relativeDirectory===props.slug){
             image=getImage(edge.node.childImageSharp)
        }
    })
    return (
        <div className={toolTipStyle}>
             <h4 className={toolTipText}>{props.name}</h4>
             <div className={toolTipImage}>
             <GatsbyImage image={image} alt="project"/>
             </div>

        </div>
        )};

const ClusterToolTip = ({children})=> {  
    return (
        <div className={clusterToolTipStyle}>
             {children}
        </div>
        )};

export default function GoogleMap(){
    const isMobile =useMediaQuery({ query: `(max-width: 760px)` }); //here stupid!
    const screenWide = useMediaQuery({ query: `(min-width: 1200px)` });
    const screenMedium =useMediaQuery({ query: `(max-width:1199px) and (min-width:760px)` }); 
    const screenSmall=useMediaQuery({ query: `(max-width:759px) and (min-width:501px)` });
    const screenMobile=useMediaQuery({ query: `(max-width: 500px)` });
    
    const zoomValue = screenWide ? 6:
                        screenMedium ? 5.65:
                            screenSmall ? 5:
                                screenMobile? 4.35 :6
   const defaultProps = {
    center: {
      lat: 39.153954,
      lng: 34.793336
    },
    zoom: zoomValue
  };
  const snazzystyles = [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#333333"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dedede"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e9e9e9"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
]
    
    
    const mapRef = useRef()
    const [toolTip,toogleToolTip]=useState(false)
    const [zoom,setZoom]=useState(zoomValue)
    const [bounds,setBounds]=useState(null)
    const [selectedProject,setSelectedProject]=useState(null)
   
    //below to create points array from data, imported points are console log data from previous array map output imported to bypass below map call
    /*const points2 = projectmarker.features.map((elem,i)=>   
        ({
            "type": "Feature",
            "properties": {
                "projectID":i,
                "Name":elem.properties.Name,
                "description":elem.properties.description,
                "gx_media_links":elem.properties.gx_media_links
            },
            "geometry": { "type": "Point", "coordinates": elem.geometry.coordinates },

          })

    )
    console.log(points2)*/


    const {clusters,supercluster}= useSuperCluster({
        points,
        bounds,
        zoom,
        options:{radius:75,maxZoom:20}
    })
    const data = useStaticQuery(graphql`
    query {
        markerIcon:allFile(filter: {name: {eq: "icon"}}) {
            nodes {
              childrenImageSharp {
                gatsbyImageData(height: 35, width: 18, layout: FIXED, quality: 50)
              }
            }
          }
        toolTip:allFile(filter: {name: {eq: "main"}}) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,height: 59
                  )
                }
                relativeDirectory
              }
            }
          }  
      }
    `)


  return (
    // Important! Always set the container height explicitly
    <div className={mapContainer}>
    <div className={mapStyle}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB88bSekpUbX0qlv1jk_1c6sLxykJz5rco" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map })=>{
            mapRef.current=map;
        }}
        onChange={({zoom,bounds})=>{
            setZoom(zoom)
            setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat            
            ])
        }}
        options={{ styles: snazzystyles }}>
        {
         clusters.map(elem=>{
            if (elem.properties.cluster){
                return(
                    <Marker 
                    pointCount={elem.properties.point_count}
                    key={'cluster-'+elem.properties.cluster_id}
                    lat={elem.geometry.coordinates[1]}
                    lng={elem.geometry.coordinates[0]}
                    >
                    
                    <button className={clusterStyle} onClick={()=>{
                          const expansionZoom = Math.min(
                            supercluster.getClusterExpansionZoom(elem.properties.cluster_id),
                            20
                          );
                          mapRef.current.setZoom(expansionZoom);
                          mapRef.current.panTo({ lat: elem.geometry.coordinates[1], lng: elem.geometry.coordinates[0] });
                    }}
                    onMouseEnter={()=>{
                        toogleToolTip(true)
                        setSelectedProject('cluster'+elem.properties.cluster_id)          
                    }}
                    onMouseLeave={()=>{
                        toogleToolTip(false)
                        setSelectedProject(null)
                    }}>
                    
                    <GatsbyImage image={data.markerIcon.nodes[0].childrenImageSharp[0].gatsbyImageData} alt='logo'  />
                     <h6 className={countStyle}>{elem.properties.point_count}</h6>
                    </button>
                    { (toolTip &&(selectedProject===('cluster'+elem.properties.cluster_id))) ? (<ClusterToolTip id={elem.properties.cluster_id}>{`${elem.properties.point_count} Projeyi Genişlet`}</ClusterToolTip>) :null }
                    </Marker>
                )
            }
            else return(    
                
                <Marker
                    key={elem.properties.projectID}
                    lat={elem.geometry.coordinates[1]}
                    lng={elem.geometry.coordinates[0]}
                    
                >  
                    
                    <Link to={"/projects/"+elem.properties.slug} style={{textDecoration:"none",color:"black"}}>
                    <button className={markerStyle} onClick={()=>{
                        console.log('/projects/'+elem.properties.slug)

                    }} onMouseEnter={()=>{
                        toogleToolTip(true)
                        setSelectedProject(elem.properties.projectID)                      


                    }}
                    onMouseLeave={()=>{
                        toogleToolTip(false)
                        setSelectedProject(null)
                    }}>
                
                     <GatsbyImage image={data.markerIcon.nodes[0].childrenImageSharp[0].gatsbyImageData} alt='logo'  />
                    </button>
                    { (toolTip &&(selectedProject===elem.properties.projectID)) ? (<ToolTip id={elem.properties.projectID}
                                                                                            name={elem.properties.Name}
                                                                                            slug={elem.properties.slug}
                                                                                            data={data}/>) :null }
                                                                                    </Link>
                </Marker>
                
            )
            }
            
            )
          
        
        }
        </GoogleMapReact>
    
    </div>
    </div>
  );
}

