// Step 1: Import React


// Google MAPS API KEY:
//AIzaSyB88bSekpUbX0qlv1jk_1c6sLxykJz5rco
import * as React from 'react'
import Layout from '/src/components/layout' 
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from '/src/components/seo'
import { graphql } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Carousel} from 'react-bootstrap'
import GoogleMap from '../components/map/googlemap'
import {container,
        mapLabel,
        mapDescp} from '../components/css/index.module.css'

// Step 2: Define your component

const IndexPage = ({data}) => {

  return (
    <Layout pageTitle="Ana Sayfa">
      <div className={container}>
      <Carousel variant="dark" style={{display:"inline-block",  width:"100%", height:"auto"}} keyboard="true" pause="hover" touch="true">
      {data.slideShow.edges.map(({node})=>(
        <Carousel.Item  key={node.id}>
          <GatsbyImage image={node.childrenImageSharp[0].gatsbyImageData} alt={node.base.split('-').join(' ').split('.')[0]}/>
        </Carousel.Item>
      ))}
      </Carousel>
      <h4 className={mapLabel}>PROJELERİMİZ</h4>
      <h5 className={mapDescp}>Projeye ulaşmak için ilgili konumdaki simgemize tıklayınız</h5>
      <GoogleMap>
        
      </GoogleMap>
      </div>
    </Layout>
    
  )
}

// You'll learn about this in the next task, just copy it for now
export const Head = () => <Seo title='Ana Sayfa'/>

// Step 3: Export your component
export default IndexPage

export const pageQuery = graphql`
query {
  slideShow: allFile(filter: {relativeDirectory: {eq: "carousel"}}) {
    edges {
      node {
        id
        base
        childrenImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            blurredOptions: {width: 100}
            quality: 100
            layout: FULL_WIDTH
            aspectRatio:2.3


          )
        }
      }
    }
  }
}
`