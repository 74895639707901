export default [
    {
        "type": "Feature",
        "properties": {
            "projectID": 0,
            "Name": "Yıldız Sarayı. Çit Kasrı",
            "description": "<img src=\"https://doc-14-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/mns7dls8gkn5uemdvoofu5ge54/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kk4udQBDRWvAzdu1hkLL05yPM-VTajYcaws_Bz7lz-adRjO2eAPNP7VgeImdyStD17vjaTmnTSUivKGRX-VMQzpGfxu7839qCHGGLDDIoMmQKrIGZbesk-r2JFNOXj21oAWzYyFL8S5qLbjK-BRNcl_QkQ-f5XYbmUqBify0xK819d9ihf7tsP6Ea1O2aSYRY?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-14-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/mns7dls8gkn5uemdvoofu5ge54/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kk4udQBDRWvAzdu1hkLL05yPM-VTajYcaws_Bz7lz-adRjO2eAPNP7VgeImdyStD17vjaTmnTSUivKGRX-VMQzpGfxu7839qCHGGLDDIoMmQKrIGZbesk-r2JFNOXj21oAWzYyFL8S5qLbjK-BRNcl_QkQ-f5XYbmUqBify0xK819d9ihf7tsP6Ea1O2aSYRY?session=0&fife",
            "slug":"yildiz-sarayi-cit-kasri"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.0111791,
                41.0506621
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 1,
            "Name": "Yıldız Sarayı, Yaveran Dairesi",
            "description": "<img src=\"https://doc-10-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/4qvc4i30uc321p8n68ph1nvro4/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lLeod4GpJzkYenIIU1cnBMiHRtbHRP7H_Z0PK6w49O7P5qG2H_i-nbFcS92ZmAdDc-a5VmWRmatqe-9JnDKj4crN6LMR-T27vF1csh3fB-CprKhUWCMlJdjyOMaCGCOf7F5955bQideM6zklk-RqchNlwfXyQlkFigKUYAyreaMCKvNPyDREYarT9ppj3lUf93?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-10-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/4qvc4i30uc321p8n68ph1nvro4/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lLeod4GpJzkYenIIU1cnBMiHRtbHRP7H_Z0PK6w49O7P5qG2H_i-nbFcS92ZmAdDc-a5VmWRmatqe-9JnDKj4crN6LMR-T27vF1csh3fB-CprKhUWCMlJdjyOMaCGCOf7F5955bQideM6zklk-RqchNlwfXyQlkFigKUYAyreaMCKvNPyDREYarT9ppj3lUf93?session=0&fife",
            "slug":"yildiz-sarayi-yaveran-dairesi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.0115681,
                41.0500452
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 2,
            "Name": "Rauf Paşa Konağı",
            "description": "<img src=\"https://doc-0k-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/i0a2epep3ej8f3novoc89kcajo/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9mMP0KRxAraIjZZsgd54BL16Zkdqq8VEX8oFLAy-PwE4UnL_0uJ1EpGpBEHG8aGG_Nzi6IcKzTrXTjUNnNv8aLimqCTH7KLxfQ7IM08Z8ejIDc-EYU5C6FYAvYBjpI2A6kYhg8hMn_kuogtOZt6WsDuKqe5AK84v3JWEA1Au1KPxBFufjvIdhVLuIAEP1F5KNc?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0k-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/i0a2epep3ej8f3novoc89kcajo/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9mMP0KRxAraIjZZsgd54BL16Zkdqq8VEX8oFLAy-PwE4UnL_0uJ1EpGpBEHG8aGG_Nzi6IcKzTrXTjUNnNv8aLimqCTH7KLxfQ7IM08Z8ejIDc-EYU5C6FYAvYBjpI2A6kYhg8hMn_kuogtOZt6WsDuKqe5AK84v3JWEA1Au1KPxBFufjvIdhVLuIAEP1F5KNc?session=0&fife",
            "slug":"rauf-pasa-konagi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                28.9748635,
                41.0110598
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 3,
            "Name": "İmrahor İlyas Bey Camii",
            "description": "<img src=\"https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/hrh6remgaukg3knl1kq23shgkg/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9nPwd8p6YlGvviZUgpntRT3vXQRM8QrMY_h2acmYZkJEDwEuQeJwcBS7ALfyNywUaQHXb-c-F95nRMWmyEohRBIzEdIZ7PmsjJ9F0-1LOZ-gsf3Si-9R65xtsrKlPDoGMyuDd1WQKmK0hHIeV8FwpkRIWvA1FYNoa2cyKHE7GoyCne9aaIEkuNeqWWntlybSvA?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/hrh6remgaukg3knl1kq23shgkg/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9nPwd8p6YlGvviZUgpntRT3vXQRM8QrMY_h2acmYZkJEDwEuQeJwcBS7ALfyNywUaQHXb-c-F95nRMWmyEohRBIzEdIZ7PmsjJ9F0-1LOZ-gsf3Si-9R65xtsrKlPDoGMyuDd1WQKmK0hHIeV8FwpkRIWvA1FYNoa2cyKHE7GoyCne9aaIEkuNeqWWntlybSvA?session=0&fife",
            "slug":"imrahor-ilyas-bey-camii"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                28.9264491,
                40.9956466
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 4,
            "Name": "Yazıcızade Cami",
            "description": "<img src=\"https://doc-0o-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/8dfer7nlcvhr4ra3geuuou0eac/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9nGiiGIefEjzP78hq5ohLbUJ1zaFKbCSAVCfeYpwvW2VGYFmjwmq_Gj_I_djcJeznIkdXDmj1fsnnWG3YeA6FNJBwtSaYjEHkJ-zqin-r9jdL9epq2RsxA52u4eS3T8m6O8fG4o3doBX1gmOHCPbHdJJXFl59kIlklHSN026IjZxzecukABHLluV_l6sM2q6BqD?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0o-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/8dfer7nlcvhr4ra3geuuou0eac/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9nGiiGIefEjzP78hq5ohLbUJ1zaFKbCSAVCfeYpwvW2VGYFmjwmq_Gj_I_djcJeznIkdXDmj1fsnnWG3YeA6FNJBwtSaYjEHkJ-zqin-r9jdL9epq2RsxA52u4eS3T8m6O8fG4o3doBX1gmOHCPbHdJJXFl59kIlklHSN026IjZxzecukABHLluV_l6sM2q6BqD?session=0&fife",
            "slug":"yazicizade-camii"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                26.6769428,
                40.40892
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 5,
            "Name": "Ortahisar Fatih Büyük Cami",
            "description": "<img src=\"https://doc-0s-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/v20h23lf7jdsotijp6rsd7v5ps/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kG6OhilN-TqA3ewsJMton2MaAmXD5zsJMFjcoBkQ0U65j239eNRwzb9Nn1wTyC-GXVcZpDXtE6Hfx839dyToDv08c-IPFu04hrUOQ8F9l0t5MLDou5O_WKlQZXH0cLxAq8o-pm6-l0vdah70OiNOjIrwU2ZemTpPXIdnq8ERkq2v7vhIIbBmnt7Q_VRVVgX7s?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0s-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/v20h23lf7jdsotijp6rsd7v5ps/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kG6OhilN-TqA3ewsJMton2MaAmXD5zsJMFjcoBkQ0U65j239eNRwzb9Nn1wTyC-GXVcZpDXtE6Hfx839dyToDv08c-IPFu04hrUOQ8F9l0t5MLDou5O_WKlQZXH0cLxAq8o-pm6-l0vdah70OiNOjIrwU2ZemTpPXIdnq8ERkq2v7vhIIbBmnt7Q_VRVVgX7s?session=0&fife",
            "slug":"orta-hisar-fatih-büyük-camii"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                39.7197029,
                41.004711
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 6,
            "Name": "Lefke Kapısı",
            "description": "<img src=\"https://doc-0o-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/pls393v5p7oa88beegqjtlo0no/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kJEniEi8JJ2w5nokB4ShUMz8NqPWulaWbXf2f7hdBD3FvX5UDU9wsvQPWHk5u6Vop_qQntUyftxCKaml7Bf5I8B9ki1LjtMlbja5H0BPfZAG1S8-L_IzSYcoyoqQnUy01-neZaBuB0vlW2v6ajMGd2ZXB2RxqQsI9lugL55VT5iQUiluXfa7GGUBrpZ9oq0pY?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0o-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/pls393v5p7oa88beegqjtlo0no/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kJEniEi8JJ2w5nokB4ShUMz8NqPWulaWbXf2f7hdBD3FvX5UDU9wsvQPWHk5u6Vop_qQntUyftxCKaml7Bf5I8B9ki1LjtMlbja5H0BPfZAG1S8-L_IzSYcoyoqQnUy01-neZaBuB0vlW2v6ajMGd2ZXB2RxqQsI9lugL55VT5iQUiluXfa7GGUBrpZ9oq0pY?session=0&fife",
            "slug":"iznik-surlari-lefke-kapi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.729072,
                40.4288241
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 7,
            "Name": "İznik Surları 2. Etap",
            "description": "<img src=\"https://doc-14-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/4qbphmk4er76lu1jno5gk2nh9c/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9mk0Fd_b_dd3ygIYSXaZjKsix8nwSZTMA4yF9JRa57foOGEQKHT8Ilk6JgQbLBYnrI7wQxYAs8qYVU2BVgLPCPexGzX6D8TGkDT6-_cgFDhT6WN2-I2uqIWOR8KGp6Vs91BC8O0PT7ZHZgcm3KWcmqJKsTxdj8iyGXozbUqB_68fivTUtpfix8CyCsxUZ4PWHg?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-14-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/4qbphmk4er76lu1jno5gk2nh9c/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9mk0Fd_b_dd3ygIYSXaZjKsix8nwSZTMA4yF9JRa57foOGEQKHT8Ilk6JgQbLBYnrI7wQxYAs8qYVU2BVgLPCPexGzX6D8TGkDT6-_cgFDhT6WN2-I2uqIWOR8KGp6Vs91BC8O0PT7ZHZgcm3KWcmqJKsTxdj8iyGXozbUqB_68fivTUtpfix8CyCsxUZ4PWHg?session=0&fife",
            "slug":"iznik-surlari-ikinci-etap"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.7287264,
                40.4281372
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 8,
            "Name": "Bilecik Hükümet Konağı",
            "description": "<img src=\"https://doc-00-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/rdqnjjpag17ne26e49233m4ld4/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kGjGjektTLtj9S0uu_3_1mU1Tz4GO3CzBBkQtPBousS6YdiTJBtBhNcbQmtNz0jypJPuQVgkhXgV-yzax0IGOVhwgt3lsUJNGYCHvH1dbsx-LlKy48WY_ItcCZAtYczrsbSjOBmQtelZjnBABWA9fZiJNQGFaHda60C0O475-jvaupW1_3tT6vaBMblFW3Vbg?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-00-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/rdqnjjpag17ne26e49233m4ld4/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kGjGjektTLtj9S0uu_3_1mU1Tz4GO3CzBBkQtPBousS6YdiTJBtBhNcbQmtNz0jypJPuQVgkhXgV-yzax0IGOVhwgt3lsUJNGYCHvH1dbsx-LlKy48WY_ItcCZAtYczrsbSjOBmQtelZjnBABWA9fZiJNQGFaHda60C0O475-jvaupW1_3tT6vaBMblFW3Vbg?session=0&fife",
            "slug":"bilecik-hukumet-konagi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.9823194,
                40.1448627
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 9,
            "Name": "Berberkaya Lahiti",
            "description": "<img src=\"https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/4piv3t8pcni40tgdu9iftqrg9s/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9n3-09uvfpKjmVmRn5hOviCxAzrc3rd_UcuGWBRFA1k7QxVwl6ufPWggwrdnssp3GHwCUXWCpNR-tYammW7nS37GxETDKJnKFVaz_WKgBkr1sA-Mt5Q6kyteXXr20xDpLdv1934wBmZlwFiAcF-x9n45y7MfOijhRDMcbgvEnUYvI6AjmpcoyYrWvkiSjuDkr8?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/4piv3t8pcni40tgdu9iftqrg9s/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9n3-09uvfpKjmVmRn5hOviCxAzrc3rd_UcuGWBRFA1k7QxVwl6ufPWggwrdnssp3GHwCUXWCpNR-tYammW7nS37GxETDKJnKFVaz_WKgBkr1sA-Mt5Q6kyteXXr20xDpLdv1934wBmZlwFiAcF-x9n45y7MfOijhRDMcbgvEnUYvI6AjmpcoyYrWvkiSjuDkr8?session=0&fife",
            "slug":"berberkaya-lahiti"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.7406142,
                40.4272105
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 10,
            "Name": "Aslıbey Konağı",
            "description": "<img src=\"https://doc-10-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/7065amgdlg3f497vps6sbqojdo/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9myoLRR-vnaQdxafQo5MTlwvmT_eVwuOAsgVE9H5MiIFWsboEEHJUv_CuWUJ0CkNhCSRwt1tPsNDyiPK58gu5GjEGXaZhJVl3wsgMcA533m-105xjYzq7RQ8aoAqpL7w0RW3k1KpH0u9YxA8qGW9tYbGv89j2u-Hhhh8wRaB2ozvOyJzgJWiieKg8_n8bz_4kY?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-10-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/7065amgdlg3f497vps6sbqojdo/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9myoLRR-vnaQdxafQo5MTlwvmT_eVwuOAsgVE9H5MiIFWsboEEHJUv_CuWUJ0CkNhCSRwt1tPsNDyiPK58gu5GjEGXaZhJVl3wsgMcA533m-105xjYzq7RQ8aoAqpL7w0RW3k1KpH0u9YxA8qGW9tYbGv89j2u-Hhhh8wRaB2ozvOyJzgJWiieKg8_n8bz_4kY?session=0&fife",
            "slug":"aslibey-konagi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                30.2655576,
                40.6880221
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 11,
            "Name": "Türk İslam Eserleri Müzesi",
            "description": "<img src=\"https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/dt2bnjaetl5fvn2ec1j8r5fhus/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9muUXNM5QoJDf4CUpRq_jO95a1gq6WvSqYjDVR9OznHwnA0guXg_rinNYfdpNR6n86d5zwyuMjtGM-xKRkaNu_76dt16fiA9fEH7OQusSjqlVktJOw46UGns7IMu6wpsUMFR2hB_KVp8HtAXwOs8P1Ckx9DQurtVhCKEQ9ZdkRjMbOiHdgWegLrMxQC3xZbUbU?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/dt2bnjaetl5fvn2ec1j8r5fhus/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9muUXNM5QoJDf4CUpRq_jO95a1gq6WvSqYjDVR9OznHwnA0guXg_rinNYfdpNR6n86d5zwyuMjtGM-xKRkaNu_76dt16fiA9fEH7OQusSjqlVktJOw46UGns7IMu6wpsUMFR2hB_KVp8HtAXwOs8P1Ckx9DQurtVhCKEQ9ZdkRjMbOiHdgWegLrMxQC3xZbUbU?session=0&fife",
            "slug":"bursa-turk-islam-eserleri-muzesi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.0733565,
                40.1814944
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 12,
            "Name": "Sivrihisar Kumacık Hamamı",
            "description": "<img src=\"https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/geuct95regmrmgotc04gf11gj0/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lUB47iIgTWuW-KBGbc6AMHmuZzsi345O7s6rK-L88lASz4dO3oklkbcbpXof0YZSfQYdofYhKMA8IRahkTsIggUHuBL8LQgAOF2wZCCoKkfZfsyxQmLmtP4T6N1BN7teqTFnvfs9Jxg7-tEuAsiPxwjwrnWMcu0dTyXD-AAqpt3-jZ6LGercpPYo8feftfxWo?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/geuct95regmrmgotc04gf11gj0/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lUB47iIgTWuW-KBGbc6AMHmuZzsi345O7s6rK-L88lASz4dO3oklkbcbpXof0YZSfQYdofYhKMA8IRahkTsIggUHuBL8LQgAOF2wZCCoKkfZfsyxQmLmtP4T6N1BN7teqTFnvfs9Jxg7-tEuAsiPxwjwrnWMcu0dTyXD-AAqpt3-jZ6LGercpPYo8feftfxWo?session=0&fife",
            "slug":"sivrihisar-kumacik-hamami"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                31.5370742,
                39.4504031
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 13,
            "Name": "Merkez Alacahan",
            "description": "<img src=\"https://doc-10-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/3lue8agu4mu5dr5farr5hfepho/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9k1lwoNVCo9EO4a5dbErQpyyT05QQHhTPmsFVspOsYCnOSNuU92Y7AT8MdaxqnOuMSGG6OJ5uvyT1881kok7VEQa8VzGVWS8hD5FLCqLrKwxOF9OWBxAOzI1cEiGA81zVA2CoBpIqLaOjVm9xFDMGypWmB-4R69skDy0FoDy_U2_UtCBOcNoZBbqOfAMnjRSDc?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-10-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/3lue8agu4mu5dr5farr5hfepho/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9k1lwoNVCo9EO4a5dbErQpyyT05QQHhTPmsFVspOsYCnOSNuU92Y7AT8MdaxqnOuMSGG6OJ5uvyT1881kok7VEQa8VzGVWS8hD5FLCqLrKwxOF9OWBxAOzI1cEiGA81zVA2CoBpIqLaOjVm9xFDMGypWmB-4R69skDy0FoDy_U2_UtCBOcNoZBbqOfAMnjRSDc?session=0&fife",
            "slug":"merkez-alacahan"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                39.7248962,
                41.0073879
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 14,
            "Name": "Ahi Evran Derneği",
            "description": "<img src=\"https://doc-0g-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/tfnv94meu25h7n5cr8etg8pd3c/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kiDxOE5EKtHs7ljRcuNPDoZ5FPeBowHgWsSTwRRh4_S6qWBQUNhR-oa0NVcg44gohGS-My5Ntz4RKatyvzaXceBsio-THZ8ADtECMPUCA3Gx55kEdsyCvm8e31XTtZZTNYAs1r9FuY3YpriLGFWKMo681KhrMgD7Gk8Cvcx9-gSXuJiM-Xkwdsu6tDd6PIHDY?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0g-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/tfnv94meu25h7n5cr8etg8pd3c/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kiDxOE5EKtHs7ljRcuNPDoZ5FPeBowHgWsSTwRRh4_S6qWBQUNhR-oa0NVcg44gohGS-My5Ntz4RKatyvzaXceBsio-THZ8ADtECMPUCA3Gx55kEdsyCvm8e31XTtZZTNYAs1r9FuY3YpriLGFWKMo681KhrMgD7Gk8Cvcx9-gSXuJiM-Xkwdsu6tDd6PIHDY?session=0&fife",
            "slug":"ahi-evran-dernegi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.920375,
                40.765135
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 15,
            "Name": "İzmit Seymen Antrepo Yapısı",
            "description": "<img src=\"https://doc-14-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/grq0hd0v9o4ecf3i84it97tqq0/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kJyDSBanX3jgBl55s8Fy-4XmXt9mL_HNx5zcflMRzXVjbHlxtvp-VVwI1IDQ_6puo72weJ8QnSOy9OEVFpCkZNuHqJA3DCYmKMoWHmDOhbkZL0YuhEPPbcJBGW8tHjk679NFlLUx_0vjCs6OJATiFOOD0d3CNyBFlrtoYp6ewDe4SgYA_nvWbo-LIp0blv0zU?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-14-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/grq0hd0v9o4ecf3i84it97tqq0/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kJyDSBanX3jgBl55s8Fy-4XmXt9mL_HNx5zcflMRzXVjbHlxtvp-VVwI1IDQ_6puo72weJ8QnSOy9OEVFpCkZNuHqJA3DCYmKMoWHmDOhbkZL0YuhEPPbcJBGW8tHjk679NFlLUx_0vjCs6OJATiFOOD0d3CNyBFlrtoYp6ewDe4SgYA_nvWbo-LIp0blv0zU?session=0&fife",
            "slug":"izmit-seymen-antrepo-yapisi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.9269545,
                40.7622463
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 16,
            "Name": "Redif Dairesi",
            "description": "<img src=\"https://doc-04-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/drrat5dbm8dbvja4iteh22hul8/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9mCXz3CXo1NXx-uaKn0XT7jLa6g-Btq2CX7GWylXM4rPB7yBCVV-Sb4h8FZXOI_mmIBzqmF1lN0-E6TPnbbhSP39yEcAuJF3--jQDKbSI3QQ6iSVk0Pk4hchV6KAaOY58oUQLtFl1CZ2KmvNVTWvIu5DdHEJeNeV9BoOCbPrx5-269Ev9Bd2bpgjjxze94BJ6w?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-04-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/drrat5dbm8dbvja4iteh22hul8/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9mCXz3CXo1NXx-uaKn0XT7jLa6g-Btq2CX7GWylXM4rPB7yBCVV-Sb4h8FZXOI_mmIBzqmF1lN0-E6TPnbbhSP39yEcAuJF3--jQDKbSI3QQ6iSVk0Pk4hchV6KAaOY58oUQLtFl1CZ2KmvNVTWvIu5DdHEJeNeV9BoOCbPrx5-269Ev9Bd2bpgjjxze94BJ6w?session=0&fife",
            "slug":"redif-dairesi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.9213579,
                40.7640477
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 17,
            "Name": "İzmit Veli Öztoprak Evi",
            "description": "<img src=\"https://doc-0s-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/jb3j9mh1gl7dd4a9ie1odvtr0c/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9mbwNDQc1JvfAgUdW5QUygV4XLfxPMOdXJbayKmEQIDZMsaKB--6EZThE1fjmZaw4qBYSJcU36KzbZCWZHXNE-mJ75dLcszq4NhQF_0_ZSO1jNOwnOR6zeyqt4Bn53h8h65_HOChEb0MGuB91PgrQd224mwWR2-0pEi1vKHuAOsJgGEFHmPwkZRwNYEYI8R8pI?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0s-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/jb3j9mh1gl7dd4a9ie1odvtr0c/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9mbwNDQc1JvfAgUdW5QUygV4XLfxPMOdXJbayKmEQIDZMsaKB--6EZThE1fjmZaw4qBYSJcU36KzbZCWZHXNE-mJ75dLcszq4NhQF_0_ZSO1jNOwnOR6zeyqt4Bn53h8h65_HOChEb0MGuB91PgrQd224mwWR2-0pEi1vKHuAOsJgGEFHmPwkZRwNYEYI8R8pI?session=0&fife",
            "slug":"izmit-veli-oztoprak-evi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.9242421,
                40.7659731
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 18,
            "Name": "Talas Han Mahallesi Sokak Sağlıklaştırma",
            "description": "<img src=\"https://doc-10-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/2j3k2l698sihhn7r5ug9poq6q8/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9msClb-sYFyB_dVIss1fFwFAKOiMOcpUxjhDfRBYMGMBpJmfb2h84_7-n8dcvjdFezCYlW5v1iRY0JToxy4C515e-jDEBkhaMzKB5sbOqGgBenDAvb1sZ8XFj-SDLCEXH7JKzRqSY9MuMlp9dZzrkjBJn2u3kzFd7vOSvHkCEOrjCBFejS8G-uH2LgNvJ8SRro?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-10-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/2j3k2l698sihhn7r5ug9poq6q8/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9msClb-sYFyB_dVIss1fFwFAKOiMOcpUxjhDfRBYMGMBpJmfb2h84_7-n8dcvjdFezCYlW5v1iRY0JToxy4C515e-jDEBkhaMzKB5sbOqGgBenDAvb1sZ8XFj-SDLCEXH7JKzRqSY9MuMlp9dZzrkjBJn2u3kzFd7vOSvHkCEOrjCBFejS8G-uH2LgNvJ8SRro?session=0&fife",
            "slug":"talas-sokak-saglamlastirma"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                35.5605239,
                38.687017
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 19,
            "Name": "Ciha Kalesi",
            "description": "<img src=\"https://doc-0g-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/qdgbtbpjn1tck4m2cg63l4huio/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9nZjdPlKBKDNrI9YeTkC4nDNQCGhPlG6kjmzpF4CZwK9WYf2uoQf_Jd0TaUEbiGYRqk8dfC3Ee107I3bPrSuz37tc40m1O-qRJLYlWmDrKO9d4VqVZaxOcvIjXWJl83suHbCM_jqNaXXr2I8uoBVCSoO_BOEoZPaQ4UjfB6W_ah8bWqe_wm1Ed06-7aRqjKCW4?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0g-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/qdgbtbpjn1tck4m2cg63l4huio/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9nZjdPlKBKDNrI9YeTkC4nDNQCGhPlG6kjmzpF4CZwK9WYf2uoQf_Jd0TaUEbiGYRqk8dfC3Ee107I3bPrSuz37tc40m1O-qRJLYlWmDrKO9d4VqVZaxOcvIjXWJl83suHbCM_jqNaXXr2I8uoBVCSoO_BOEoZPaQ4UjfB6W_ah8bWqe_wm1Ed06-7aRqjKCW4?session=0&fife",
            "slug":"ciha-kalesi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                40.9004769,
                41.1041917
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 20,
            "Name": "Sinop Kalesi",
            "description": "<img src=\"https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/sfcpchgda54un48igtspse46hs/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9me-jZxJiFmeveLSZ9I4RXXvHdDRQn7K6NhKmNbzpl9tD6rPbJ5KhVt0DbzdWgkz9LiWeqMtxTOoz96ogtA60cAFIs1zpUNOdBG5si-0xDBMaSLsd9_XTGoTKwbGgiaRBka517LInqhE6SlaSBnEtjZPOTnDuQAfdC_lUXB8LrjoZZMMmR1pJPt48mKVyQmEsE?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/sfcpchgda54un48igtspse46hs/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9me-jZxJiFmeveLSZ9I4RXXvHdDRQn7K6NhKmNbzpl9tD6rPbJ5KhVt0DbzdWgkz9LiWeqMtxTOoz96ogtA60cAFIs1zpUNOdBG5si-0xDBMaSLsd9_XTGoTKwbGgiaRBka517LInqhE6SlaSBnEtjZPOTnDuQAfdC_lUXB8LrjoZZMMmR1pJPt48mKVyQmEsE?session=0&fife",
            "slug":"sinop-kalesi-bati-surlari"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                35.1507759,
                42.0238647
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 21,
            "Name": "Kale-i-Bala",
            "description": "<img src=\"https://doc-0k-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/j0vbbe5878pt7tmr9jedb74qkc/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kLf40OcVN6Kgg9w7TvHEDcwgr-bEIcTAceweCufSUglbkELNTGLpPJvID8hsVcwxf5TYfQwMVLu2bKeqo3q7czDgHlFiMJtxblA-ov8Jg31KspOF4YdWYk5Astsh-yptwPa5LP4RxZNGxsGyr0IscDAfBRAEdfcRlqrWjCdSy73EQypwDKmxVHNVwUB-oCByw?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0k-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/j0vbbe5878pt7tmr9jedb74qkc/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kLf40OcVN6Kgg9w7TvHEDcwgr-bEIcTAceweCufSUglbkELNTGLpPJvID8hsVcwxf5TYfQwMVLu2bKeqo3q7czDgHlFiMJtxblA-ov8Jg31KspOF4YdWYk5Astsh-yptwPa5LP4RxZNGxsGyr0IscDAfBRAEdfcRlqrWjCdSy73EQypwDKmxVHNVwUB-oCByw?session=0&fife",
            "slug":"kale-i-bala-kalesi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                40.9428897,
                40.8131372
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 22,
            "Name": "Gölcük Roma Ilıcası",
            "description": "<img src=\"https://doc-14-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/l18ep7sh13mj7jitmbmntug6c8/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kFErZiR_Oz_x7wn19qyX7qSUNEWU0Cm8PlkTvRSvhcDiXCSPKr3_MkjeIPIwWqW3H00Y1ZA4OI5piNmYZdM2J9VkCnOFMBOHegJmaLoRVykUXVH7Ip1kFLvNnHjrWYtNC-W856ISdtiYaR3r8cbDHZdxNu1kdYlDVFi6THUeZhdxf2VfoQC4BQficO69oyICMp?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-14-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/l18ep7sh13mj7jitmbmntug6c8/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kFErZiR_Oz_x7wn19qyX7qSUNEWU0Cm8PlkTvRSvhcDiXCSPKr3_MkjeIPIwWqW3H00Y1ZA4OI5piNmYZdM2J9VkCnOFMBOHegJmaLoRVykUXVH7Ip1kFLvNnHjrWYtNC-W856ISdtiYaR3r8cbDHZdxNu1kdYlDVFi6THUeZhdxf2VfoQC4BQficO69oyICMp?session=0&fife",
            "slug":"golcuk-roma-donemi-ilicasi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.8776663,
                40.6979269
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 23,
            "Name": "Hacı Ali Paşa Cami",
            "description": "<img src=\"https://doc-00-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/66cskfoi9mnmrdd6nm6lojo36c/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lrXKxr8_KqHXwVyuswK4q5o_ZOyVd7eL79jpswKTSSnYlUTzHk0xeKfVsg2_NQ4aJIPT_blEE3LgpxMLp_GKgLBEB5W-EEUAJ14eVMNKR-Hj444Ily1toZY1njUQrkUNbi71pI2VWg2HmZuT7GzrS_n9W1xBUJLTUFes2GK_1c2awVf0-h-gm5nBkVgLj5gPac?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-00-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/66cskfoi9mnmrdd6nm6lojo36c/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lrXKxr8_KqHXwVyuswK4q5o_ZOyVd7eL79jpswKTSSnYlUTzHk0xeKfVsg2_NQ4aJIPT_blEE3LgpxMLp_GKgLBEB5W-EEUAJ14eVMNKR-Hj444Ily1toZY1njUQrkUNbi71pI2VWg2HmZuT7GzrS_n9W1xBUJLTUFes2GK_1c2awVf0-h-gm5nBkVgLj5gPac?session=0&fife",
            "slug":"armutlu-haci-ali-pasa-camii"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                28.828562,
                40.519228
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 24,
            "Name": "Yücehisar Camii",
            "description": "<img src=\"https://doc-0g-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/iu7g9068o5hpoeu9u1lj5mm2i8/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kygBk0bCzpMRrflkMbb1gvxVpxNTyxDS9ttltH7WdiEQt1WSjNIGe2RhDxqt-mZh2Ic2BA-PTUtQGxdaOMjqIv1D9EDtzjKtQJBW20bpZs2gp9Vw72543L7XCeVzeKy9X4j0_oeQJgeQjCv_xwjGKqOJta6RqncimseIGsu6eyRLmad9M86P0-Y7p-YI-onfs?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0g-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/iu7g9068o5hpoeu9u1lj5mm2i8/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kygBk0bCzpMRrflkMbb1gvxVpxNTyxDS9ttltH7WdiEQt1WSjNIGe2RhDxqt-mZh2Ic2BA-PTUtQGxdaOMjqIv1D9EDtzjKtQJBW20bpZs2gp9Vw72543L7XCeVzeKy9X4j0_oeQJgeQjCv_xwjGKqOJta6RqncimseIGsu6eyRLmad9M86P0-Y7p-YI-onfs?session=0&fife",
            "slug":"yucehisar-koyu-camii"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                40.91156,
                41.121894
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 25,
            "Name": "Akbaş Köprüsü",
            "description": "<img src=\"https://doc-0g-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/0tp4fjqhk9vs88johog8f3u36c/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lm9wh68KDbqiKzEWQmDDvWlHc2GB9VH7n3DXwq571fjXfRxEVuapP2jDul8V8YRdNULKHG5hGttD9mwsJEfnxpgMHgCRneX8EHm16V25dhQvM6qqeH07_poQEgx9GJwbZjtUoUDzn_x472sWY6AEj2K5Z5eOtdqUXFDFo9YhfLLEjF4PB1M8HJH2vFr_kng_c?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0g-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/0tp4fjqhk9vs88johog8f3u36c/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lm9wh68KDbqiKzEWQmDDvWlHc2GB9VH7n3DXwq571fjXfRxEVuapP2jDul8V8YRdNULKHG5hGttD9mwsJEfnxpgMHgCRneX8EHm16V25dhQvM6qqeH07_poQEgx9GJwbZjtUoUDzn_x472sWY6AEj2K5Z5eOtdqUXFDFo9YhfLLEjF4PB1M8HJH2vFr_kng_c?session=0&fife",
            "slug":"akbas-koprusu"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                32.8315975,
                40.9053858
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 26,
            "Name": "Taşköprü",
            "description": "<img src=\"https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/hoo5s2iuif7ehqul3r5n3one2k/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9k27QAtR_s6Rah00TIs7gDtFGxclEVdYDJb4L2NYs5-X2oZK9ebLiu87U91rfzGWNers76ETFVqdxKqOClzQdlPZmOR-V_DdKTFuMrN-tmlAOIgvxtFm8VC2-zExDltsX2GL5icE-OJ7whiXWZ455wKOxLPjxgYzrIMfinMUbht9NhO0mQl12IQBI2y8FmB5SQ?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/hoo5s2iuif7ehqul3r5n3one2k/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9k27QAtR_s6Rah00TIs7gDtFGxclEVdYDJb4L2NYs5-X2oZK9ebLiu87U91rfzGWNers76ETFVqdxKqOClzQdlPZmOR-V_DdKTFuMrN-tmlAOIgvxtFm8VC2-zExDltsX2GL5icE-OJ7whiXWZ455wKOxLPjxgYzrIMfinMUbht9NhO0mQl12IQBI2y8FmB5SQ?session=0&fife",
            "slug":"taskopru"
        },  
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.392721,
                40.675914
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 27,
            "Name": "Sivrihisar Sokak Sağlıklaştırma",
            "description": "<img src=\"https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/gsajni4qnoe78vdmuavoqhlu1k/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9l3o6Z3pygSh5Hl_ypEXqPsL9n0Gfs7pCiFXxHKD0Mpv3qTp2wfKKpmisxeGnVjlkBBYmK3IdZekqlja1sIVbBCkXXzlkN2mUVecAUs0pZMjIEO1Np-F4y1VtTffvvtK3vpuYIaAd59Qolvt9MtRCDPnbkoCknJZrZYn_KgOtKi9L9JOxkavLne3yWIcIUWhII?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/gsajni4qnoe78vdmuavoqhlu1k/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9l3o6Z3pygSh5Hl_ypEXqPsL9n0Gfs7pCiFXxHKD0Mpv3qTp2wfKKpmisxeGnVjlkBBYmK3IdZekqlja1sIVbBCkXXzlkN2mUVecAUs0pZMjIEO1Np-F4y1VtTffvvtK3vpuYIaAd59Qolvt9MtRCDPnbkoCknJZrZYn_KgOtKi9L9JOxkavLne3yWIcIUWhII?session=0&fife",
            "slug":"sivrihisar-sokak-saglamlastirma"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                31.5378,
                39.4513
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 28,
            "Name": "İzmit Akçakoca Konak",
            "description": "<img src=\"https://doc-0s-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/i1te00c4j43vuos7qpkhdqmupc/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kWUO9dyEb1jfs84gS5nvpmnDD31Apo1lbhHPY_Wtq7kh3GB_K7fCrxaxP-OLr_-V_sqh0rTjY5E6G5jO9N46EQaqsMt5uYLI_tJ7hD_6dZPA7crs7MWTq_vr6mcEiQJcKOfbfFqsPPEMMEbY3AMi9U-qgBaKaXZw6VL1a6HpnP3Z__pppCKEUAP6fQJ9112poK?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0s-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/i1te00c4j43vuos7qpkhdqmupc/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kWUO9dyEb1jfs84gS5nvpmnDD31Apo1lbhHPY_Wtq7kh3GB_K7fCrxaxP-OLr_-V_sqh0rTjY5E6G5jO9N46EQaqsMt5uYLI_tJ7hD_6dZPA7crs7MWTq_vr6mcEiQJcKOfbfFqsPPEMMEbY3AMi9U-qgBaKaXZw6VL1a6HpnP3Z__pppCKEUAP6fQJ9112poK?session=0&fife",
            "slug":"izmit-akcakoca-konak"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.9208288,
                40.7672637
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 29,
            "Name": "İznik Antik Roma Tiyatrosu",
            "description": "<img src=\"https://doc-14-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/qlv672g8btjuvkahf8o5dvcphk/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kkj9mqVEJpQqr1W2kmoZju29l7DlfbrAgtUq7PfBKqCrKeIZzcKtVNVdohb8uZysD_-ouVHAbqf9yd97N7VTVxVI4G3q0_UeklsdN2HLim1C5ZuZDfTj69DjyaUmlb2UKzH_wWlbG5nGsZmQekqmoO6eJ8LpZ-HbHR7A60uMBZM-xInKZlowQrJ09QGZQG-4c?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-14-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/qlv672g8btjuvkahf8o5dvcphk/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kkj9mqVEJpQqr1W2kmoZju29l7DlfbrAgtUq7PfBKqCrKeIZzcKtVNVdohb8uZysD_-ouVHAbqf9yd97N7VTVxVI4G3q0_UeklsdN2HLim1C5ZuZDfTj69DjyaUmlb2UKzH_wWlbG5nGsZmQekqmoO6eJ8LpZ-HbHR7A60uMBZM-xInKZlowQrJ09QGZQG-4c?session=0&fife",
            "slug":"iznik-antik-roma-tiyatrosu"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.714707,
                40.4244014
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 30,
            "Name": "Philokrene (Darıca) Kalesi",
            "description": "<img src=\"https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/k1k5j88905l3gkncoc5blitl40/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kNrs6tP9ovVJot2GZFBbAMWsI-kGUf35ln2inUahqPIiH8qPqdyjhv2YBYLYM6IvStzvnc8y9g0KsGcDHOmMJnsSWysWcl-8BfNc5AoN7bwoRmEZMRWszfQFy4y25ycm07BYoz6PwGFcJ-aAYaNCoLCK6rjs7RFCMbeqL_xGE1RshGlEIg6T-EaYuJ6R8QnGw?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/k1k5j88905l3gkncoc5blitl40/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kNrs6tP9ovVJot2GZFBbAMWsI-kGUf35ln2inUahqPIiH8qPqdyjhv2YBYLYM6IvStzvnc8y9g0KsGcDHOmMJnsSWysWcl-8BfNc5AoN7bwoRmEZMRWszfQFy4y25ycm07BYoz6PwGFcJ-aAYaNCoLCK6rjs7RFCMbeqL_xGE1RshGlEIg6T-EaYuJ6R8QnGw?session=0&fife",
            "slug":"philokrene-darica-kalesi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.3537086,
                40.7709516
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 31,
            "Name": "Şemaki Evi Müzesi",
            "description": "<img src=\"https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/aeltt33amhuadhjrahskrum1ks/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9k2FoL88n0bZFvJdvnrghskBnuJo6DZYMWCNUcGO9nNy3Eqz9V01K7foSMMm_iACVqI7rEYjFV3Vx-0ruLCSUY9b1aYZi51ML4bY2U_YhJuZZNhNtvjxcORAvwQluPLQv-nOJnsS1Ro_9mF18LtczpRdRYgbLpL8Z5Tj3ta7ChTLShqmJvvFlBoOsbhg-r0Iwub?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/aeltt33amhuadhjrahskrum1ks/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9k2FoL88n0bZFvJdvnrghskBnuJo6DZYMWCNUcGO9nNy3Eqz9V01K7foSMMm_iACVqI7rEYjFV3Vx-0ruLCSUY9b1aYZi51ML4bY2U_YhJuZZNhNtvjxcORAvwQluPLQv-nOJnsS1Ro_9mF18LtczpRdRYgbLpL8Z5Tj3ta7ChTLShqmJvvFlBoOsbhg-r0Iwub?session=0&fife",
            "slug":"semaki-evi-muzesi"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.6515282,
                40.262562
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 32,
            "Name": "Taş Mektap (Mal Hatun)",
            "description": "<img src=\"https://doc-0s-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/9s4uniah5l0qcktabjaphq0ag0/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9maywIFxSTgSGqVYmFkaWnjwprt4y81KVCLfXq6PMITvPkFTYwhTPFxUWakrCM6FJ4xOSQHvEj1QRN9h5TFzK_-A4YKh9s74bOCTCpCOUZ1G-_hmjMbqVO1889GD-7GMB-eQ3MmPPIRo-BGwo6V4FOsuLjuy6HkGDOKSAhiJoQI9cBl7I39AI2U235fr_fIyFM?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0s-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/9s4uniah5l0qcktabjaphq0ag0/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9maywIFxSTgSGqVYmFkaWnjwprt4y81KVCLfXq6PMITvPkFTYwhTPFxUWakrCM6FJ4xOSQHvEj1QRN9h5TFzK_-A4YKh9s74bOCTCpCOUZ1G-_hmjMbqVO1889GD-7GMB-eQ3MmPPIRo-BGwo6V4FOsuLjuy6HkGDOKSAhiJoQI9cBl7I39AI2U235fr_fIyFM?session=0&fife",
            "slug":"tarihi-tas-mektep-mal-hatun"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                29.9833057,
                40.1420857
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 33,
            "Name": "Merkez Ağa Camii",
            "description": "<img src=\"https://doc-0k-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/5019kkm02m58r43nmdh5g7fe18/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9mh3Gu4jiBibXmcrracsRhn_66rQg_bkVwx1KP1zwBWq7tILeOLYPEmmL-MI4usv_rHouGJrq4-nllhITaMPMW3IsS9FuxgGLaML7lDVZiKfnTyiwKwzmuv2fUbwt4uojYvyeXEOVAqiUYzsdLh4O-hOdSecUv-8-7TV5opirQVe66g1rZ0ZrzzAPfxJChaGs4?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0k-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/5019kkm02m58r43nmdh5g7fe18/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9mh3Gu4jiBibXmcrracsRhn_66rQg_bkVwx1KP1zwBWq7tILeOLYPEmmL-MI4usv_rHouGJrq4-nllhITaMPMW3IsS9FuxgGLaML7lDVZiKfnTyiwKwzmuv2fUbwt4uojYvyeXEOVAqiUYzsdLh4O-hOdSecUv-8-7TV5opirQVe66g1rZ0ZrzzAPfxJChaGs4?session=0&fife",
            "slug":"merkez-aga-camii"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                30.4005397,
                40.7808189
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 34,
            "Name": "Çavuşlu Camii",
            "description": "<img src=\"https://doc-0o-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/go87o52pdt0ttkuv5ottkr85a8/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lxv83XJX61LoWhQT7-MlOfXzEDsviHEZxJUjE1jQ66_b7a3Temp3sikDYHw1oC7OPBcLZ1mQvkFT0Sfg04pgQkx_SbBAlVqpAGwCJGz2wsgAsWMp8F8sU2Z2UtVjpuigCZCUWNUVoXWHPWbmpmXD567cY4UfkpluSYf62DpGbY3yed7aaZC8PIRuxp8Xzr_fo?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0o-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/go87o52pdt0ttkuv5ottkr85a8/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lxv83XJX61LoWhQT7-MlOfXzEDsviHEZxJUjE1jQ66_b7a3Temp3sikDYHw1oC7OPBcLZ1mQvkFT0Sfg04pgQkx_SbBAlVqpAGwCJGz2wsgAsWMp8F8sU2Z2UtVjpuigCZCUWNUVoXWHPWbmpmXD567cY4UfkpluSYf62DpGbY3yed7aaZC8PIRuxp8Xzr_fo?session=0&fife",
            "slug":"cavuslu-camii"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                39.0649343,
                41.039474
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 35,
            "Name": "Serik Bizans Dönemi Köprüsü",
            "description": "<img src=\"https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/jfhgnc0vrvecrrul236eovc2ik/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9nSM5eyQUg8FVFLzl-FqRa805oz-Lu9O1X7NQPav4Z7LbPZVnEKyCW6stx71BR5SdWFIXHfP3V_jSBMIHGjOboRNB_mFKk6ZcDYXAUN6Dus28roYrORPybtcCUe1q7ApNgKaFBvCSnN367PgHFQvlSaDNLShKn9ko3V6f2RP37wBdqOmUSgjmJey9UuKcFABPk?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-0c-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/jfhgnc0vrvecrrul236eovc2ik/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9nSM5eyQUg8FVFLzl-FqRa805oz-Lu9O1X7NQPav4Z7LbPZVnEKyCW6stx71BR5SdWFIXHfP3V_jSBMIHGjOboRNB_mFKk6ZcDYXAUN6Dus28roYrORPybtcCUe1q7ApNgKaFBvCSnN367PgHFQvlSaDNLShKn9ko3V6f2RP37wBdqOmUSgjmJey9UuKcFABPk?session=0&fife",
            "slug":"serik-bizans-donemi-koprusu"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                31.104447,
                36.917399
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 36,
            "Name": "Hz. Cabir Camii",
            "description": "<img src=\"https://doc-04-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/fiifflfg9kpsuql3rjrioi0mjc/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lE4L_AkW8Xl8YbyuBYtSrut2uVwgm9QkkCB2O6iGjr9ffFObC1ME6zrMU-lWhduHgfl1o2PRM8u4UjisUk2AQban9rKjoilfwUW-Xz7dVEdDxxwHVz0C9iUBE8vK6FjYymFPf5AQcKl-wuk2lDLA_31Hyz3MTBDUqnTbfL1ZHKXW6IPx7-Y6MdI3PABmZZxfs?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-04-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/fiifflfg9kpsuql3rjrioi0mjc/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9lE4L_AkW8Xl8YbyuBYtSrut2uVwgm9QkkCB2O6iGjr9ffFObC1ME6zrMU-lWhduHgfl1o2PRM8u4UjisUk2AQban9rKjoilfwUW-Xz7dVEdDxxwHVz0C9iUBE8vK6FjYymFPf5AQcKl-wuk2lDLA_31Hyz3MTBDUqnTbfL1ZHKXW6IPx7-Y6MdI3PABmZZxfs?session=0&fife",
            "slug":"hz-cabir-camii"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                28.9438889,
                41.0386111
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "projectID": 37,
            "Name": "Hacı Evhaddin Camii",
            "description": "<img src=\"https://doc-10-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/f1o36m36c1kb3l73864q0snsbk/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kzCNGMOkRH1ecHVNpi-p_nhsw3vjo_tQ_Cx_nX1WmcFhPy1P_hcklGRzkCRJW6NREbnq1YSN7T7Gd-W_5n1yEZF7ogVOAZU4gHVCutOsG8UkqFqGwYS_vJu5qixQOTr1C1YRnOWY-HLWgZ1rM-Ym5rlI6d2jhKsLsDa2B8reYK7SuOXY_XOv7bdlxLSLW7xJk?session=0&fife\" height=\"200\" width=\"auto\" /><br><br>",
            "gx_media_links": "https://doc-10-c4-mymaps.googleusercontent.com/untrusted/hostedimage/omonj0e4qkbs7g6enm8uqdd8sg/f1o36m36c1kb3l73864q0snsbk/1666853090500/JigHgoROgQEhk30aayS2jzq4aPYiWr06/02370090185852347544/5AKgB-9kzCNGMOkRH1ecHVNpi-p_nhsw3vjo_tQ_Cx_nX1WmcFhPy1P_hcklGRzkCRJW6NREbnq1YSN7T7Gd-W_5n1yEZF7ogVOAZU4gHVCutOsG8UkqFqGwYS_vJu5qixQOTr1C1YRnOWY-HLWgZ1rM-Ym5rlI6d2jhKsLsDa2B8reYK7SuOXY_XOv7bdlxLSLW7xJk?session=0&fife",
            "slug":"haci-evhaddin-camii"
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                28.9239444,
                40.9967473
            ]
        }
    }
]